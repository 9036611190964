@font-face {
  font-family: "Roboto";
  src: url("./font/Roboto/Roboto-Regular.ttf") format("ttf");
}

* {
  margin: 0;
  padding: 0;
  font-family: 'Roboto';
}

body {
  margin: 0.5vw;
  background-color: #bdbdbd;
  font-family: 'Roboto';
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

::-webkit-scrollbar {
  width: 10px;
}

/* Track */
::-webkit-scrollbar-track {
  background: #f1f1f1;
}

/* Handle */
::-webkit-scrollbar-thumb {
  background: #888;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: #555;
}