.footer {
    margin-top: 0.9vh;
    height: 6.5vh;
    background-color: #ffff;
}

.footer-content{
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
}
.footer-content > img{
    height: 6vh;
}