.form {
    display: flex;
    flex-direction: column;
    width: 30%;
    padding: 2vh 2vw;
}

.inputContainer {
    display: flex;
    align-items: center;
    height: 6vh;
    margin-bottom: 3vh;
}

.label {
    font-size: 1.2vw;
}

.saveBox {
    display: flex;
    justify-content: flex-end;
}

.saveBox>button {
    flex-grow: 1;
    cursor: pointer;
    position: relative;
    padding: 0.5vh 1.2vw;
    border: 0.075rem solid black;
    color: black;
    font-size: 1.2vw;
    background-color: white;
    transition: flex-grow 250ms cubic-bezier(0.215, 0.61, 0.355, 1);
}

.saveBox>button:hover {
    background-color: #e2e2e2
}

.selectTypeDoc {
    height: 100%;
    font-size: 1.2vw;
    outline: 0;
    border: 1px solid #ccc;
    border-radius: 1vh;
    border-left: none;
    border-top-left-radius: 0;
    border-bottom-left-radius: 0;
}

.input{
    padding-left: 1vw;
    font-size: 1.2vw;
    width: 100%;
    height: 96%;
    display: inline-block;
    border: 1px solid #ccc;
    border-radius: 1vh;
    border-right: none;
    border-top-right-radius: 0;
    border-bottom-right-radius: 0;
    outline: 0;
}

.input:focus{
    border: 1px solid #ccc;
}