@import url('//maxcdn.bootstrapcdn.com/font-awesome/4.7.0/css/font-awesome.min.css');

.isa_error {
    padding: 0.5vh 0.5vw;
}

.isa_error {
    color: #D8000C;
    background-color: #FFD2D2;
}

.isa_error i {
    margin: 0 0.5vw 0 0;
    font-size: 1.3rem;
    vertical-align: middle;
}

.custom-form {
    display: flex;
    justify-content: center;
    flex-direction: column;
    align-items: center;
}

.column-form {
    width: 50%;
    display: flex;
    flex-direction: column;
}

.tools-item-box {
    display: flex;
    justify-content: flex-end;
}

.remove-box:last-child {
    padding-bottom: 2vh;
}

.remove-box>button {
    color: #fff;
    background-color: #dc3545;
    border-color: #dc3545;
    display: inline-block;
    font-weight: 400;
    text-align: center;
    white-space: nowrap;
    vertical-align: middle;
    user-select: none;
    border: 1px solid transparent;
    padding: 0.375rem 0.75rem;
    font-size: 1rem;
    line-height: 1.5;
    border-radius: 0.25rem;
    transition: color .15s ease-in-out, background-color .15s ease-in-out, border-color .15s ease-in-out, box-shadow .15s ease-in-out;
}

.remove-box>button:hover {
    background-color: rgb(130, 1, 1);
}

.remove-box>button:focus {
    box-shadow: 0 0 0 0.2rem rgb(220 53 69 / 50%);
}

.notification-box {
    padding: 1rem 0 0 1vw;
    display: flex;
    justify-content: flex-start;
}

.notification-alert {
    border-radius: 10%;
    padding: 1vh 10vw;
    display: block;
    border: 0.2vh solid black;
    color: red;
    font-size: 1.3rem;
}

.column-form>label {
    font-size: 1rem
}

.tools-box {
    padding: 1vh 0 1rem 1vw;
    display: flex;
    width: 40%;
}

.tools-box button {
    flex-grow: 1;
    cursor: pointer;
    position: relative;
    padding: 1vh 1vw;
    border: 0.125rem solid black;
    color: black;
    font-size: 1.2vw;
    background-color: white;
    text-shadow: rgba(0, 0, 0, 0.1) 2px 2px;
    transition: flex-grow 250ms cubic-bezier(0.215, 0.61, 0.355, 1);
}

.tools-box button+button {
    border-left: 0.125rem solid black;
    margin-left: calc(0.125rem * -1);
}

.tools-box button:hover,
.tools-box button:focus {
    flex-grow: 2;
    color: white;
    outline: none;
    text-shadow: none;
    border: none;
}

.red:focus,
.red:hover {
    background-color: red;
}

.green:focus,
.green:hover {
    background-color: #00a300;
}

.tools-box button:focus {
    outline: 0.125rem dashed white;
    outline-offset: calc(0.125rem * -3);
}

.tools-box button:active {
    transform: translateY(0.125rem);
}

.tools-box button:disabled {
    cursor: not-allowed;
    color: #fff;
    background-color: #6c757d;
    border-color: #6c757d;
}

textarea::-webkit-scrollbar {
    width: 1em;
}

textarea::-webkit-scrollbar-track {
    -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
}

textarea::-webkit-scrollbar-thumb {
    background-color: darkgrey;
    outline: 1px solid slategrey;
}

textarea {
    font-size: 1.2vw;
    width: 100%;
    height: 150px;
    margin: 2vh 0;
    padding: 0.8vh 1vw;
    box-sizing: border-box;
    border: 2px solid #ccc;
    border-radius: 4px;
    background-color: #f8f8f8;
    resize: vertical;
}

textarea:focus,
input:focus {
    border: 2px solid #555;
}

.inputTypeText {
    font-size: 1.2vw;
    width: 100%;
    padding: 1.55vh 3vh;
    margin: 2.65vh 0;
    display: inline-block;
    border: 1px solid #ccc;
    border-radius: 1vh;
    box-sizing: border-box;
}

