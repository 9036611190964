.containterB {
    display: flex;
    flex-direction: column;
    border: 0.5vh black solid;
}

.containterB-top-item {
    display: flex;
    flex-direction: row;
}

.containterB-bottom-item {
    display: flex;
    flex-direction: row;
}

.cardB-bottom {
    height: 18.8vh;
    width: 33%;
}

.cardB-full {
    height: 69.3vh;
    width: 17.5vw;
}

.cardB-half {
    height: 34.65vh;
    width: 17.5vw;
}

.cardB {
    display: flex;
    flex-direction: column;
}

.cardB-three-top-bottom {
    height: 20.8vh;
    width: 26.7vw;
}

.cardB-three-main {
    height: 26.9vh;
    width: 26.7vw;
}

.border-bottom {
    border-bottom: 0.4vh black solid;
}

.border-right {
    border-right: 0.4vh black solid;
}

.border-left {
    border-left: 0.4vh black solid;
}

.border-top {
    border-top: 0.4vh black solid;
}