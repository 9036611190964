.containter {
    display: flex;
    flex-direction: column;
    border: 0.5vh black solid;
}

.top-line {
    display: flex;
}

.top-card {
    display: flex;
    flex-direction: column;
}

.top-card-full {
    height: 69.3vh;
    width: 19.55vw;
}

.top-card-half {
    height: 34.675vh;
    width: 19.55vw;
}

.bottom-line {
    border-top: 0.4vh black solid;
    display: flex;
}

.bottom-card {
    width: 50%;
    height: 20.8vh;
}

.bottom-card-right {
    border-left: 0.4vh black solid;
}

.border-top {
    border-top: 0.4vh black solid;
}

.border-left-right {
    border-left: 0.4vh black solid;
    border-right: 0.4vh black solid;
}

.categoryName {
    font-weight: bold;
    padding: 2.2vh 0.5vh 0 2vh;
    font-size: 1.25vw;
}

.cardContent {
    overflow-wrap: break-word;
    font-size: 1.1vw;
    padding: 0.5vh 0.5vh 0 2vh;
}

.hr-card {
    margin: 0.5vh 1vw 0 1vw;
    height: 3px;
    border: none;
}