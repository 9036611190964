.container {
    padding-top: 10vh;
}

.dropzone {
    text-align: center;
    padding: 30px;
    border: 3px dashed #777777;
    background-color: #fafafa;
    color: #000000;
    cursor: pointer;
    margin-bottom: 20px;
    margin-left: 15vw;
    margin-right: 15vw;
    height: 30vh;
}

.selected-file-wrapper {
    text-align: center;
}

.selected-file {
    color: #000;
    font-weight: bold;
}

.btn {
    color: rgb(0, 0, 0);
    display: inline-block;
    font-weight: 400;
    text-align: center;
    white-space: nowrap;
    vertical-align: middle;
    user-select: none;
    border: 1px solid black;
    padding: 1vh 5vw;
    font-size: 1rem;
    line-height: 1.5;
    border-radius: 0.25rem;
    cursor: pointer;
}

.btn:hover{
    border: none;
    background-color:gray;
}   

.btn:disabled{
    cursor: no-drop;

}
.container {
    padding-top: 3vh;
}

.notification-box-import {
    width: 33%;
    padding: 0 0 2vh 1vw;
}
.notification-box-import-p {
    padding: 1vh 0 0 1.5vw;
}