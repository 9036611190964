.navigation>a {
    cursor: pointer;
    box-shadow: 5px 6px 15px 0 #959595;
    padding: 1vh 1vw 1vh 1vw;
    margin-left: 35vw;
    margin-right: 35vw;
    display: flex;
    color: black;
    text-decoration: none;
    font-size: 1.5vw;
}

.navigation>a:nth-child(2n) {
    margin-top: 2vh;
    margin-bottom: 2vh;
}

.navigation {
    padding-top: 25vh;
    display: flex;
    justify-content: center;
    align-content: center;
    flex-direction: column;
}
.changeLanguage{
    display: flex;
    justify-content: flex-end;
    margin-left: 35vw;
    margin-right: 35vw;
}
.changeLanguage>a {
    cursor: pointer;
    box-shadow: 5px 6px 15px 0 #959595;
    padding: 0.5vh 0vw 0vh 0.5vw;
    text-decoration: none;
    font-size: 1.5vw;
}