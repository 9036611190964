.card{
    box-shadow: 0px 0px 0px 0.05vw rgb(95, 97, 97);    
    width: 17vw;
    overflow-wrap: break-word;
    margin-bottom: 5vh;  
}

.card-categories{
    font-weight: 800;
    padding-top: 0.5vw;
    min-height: 10vh;
    font-size: 1.2vw;
}

.card-content{
    font-weight: 400;
    height: 27vh;
    font-size: 1.6vw;
}