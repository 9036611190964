header {
    display: flex;
    padding: 0.2vw 0;
    width: 100%;
    background-color: #bdbdbd;
}

.header-btn-container {
    display: flex;
    border-radius: 0 100vw 0 0;
    background-color: white;
    padding: 1vw;
    width: 65vw;
}

.header-btn-container>a,
button {
    color: black;
    text-decoration: none;
    font-size: 1.3vw;
    box-shadow: 5px 6px 15px 0 #959595;
    border: none;
    cursor: pointer;
    padding: 1.5vh 2vw;
    background-color: transparent;
}

.header-btn-container>a:nth-child(2n+2),
.header-btn-container>button:nth-child(2n+2) {
    margin-left: 1.5vw;
    margin-right: 1.5vw;
}

.logo-container {
    padding: 2vh 0 0 1vw;
    font-size: 2.2vw;
}

.select{
    font-size: 1.2vw;
    border: none;
    outline: 0;
    box-shadow: 5px 6px 15px 0 #959595;
    margin-left: 1vw;
    width: 15vw;
}